<template>
  <div class="page">
    <Navbar :isBack="true" :title="$t('login.changePassword')"/>
    <div class="main">
      <div class="cell">
        <SvgIcon name="email" class="cell_icon" :color="$config.primary_color" />
        <div class="cell_input"> <input type="text" v-model="email" :placeholder="$t('login.emailPlaceholder')"> </div>
      </div>
      <div class="login_code">
        <div class="cell">
          <SvgIcon name="code" class="cell_icon" :color="$config.primary_color" />
          <div class="cell_input"> <input type="text" v-model="code" :placeholder="$t('login.codePlaceholder')"> </div>
        </div>
        <div @click="getCode()" class="login_code_btn">{{codeTime > 0 ? codeTime : $t('login.getCode')}}</div>
      </div>
      <div class="cell">
        <SvgIcon name="password" class="cell_icon" :color="$config.primary_color" />
        <div class="cell_input"> <input type="text" v-model="password" :placeholder="$t('login.newPassword')"> </div>
      </div>
      <div class="cell">
        <SvgIcon name="password" class="cell_icon" :color="$config.primary_color" />
        <div class="cell_input"> <input type="text" v-model="password2" :placeholder="$t('login.repeatPassword')"> </div>
      </div>
      <div class="submit_btn" @click="confirm()">{{$t('login.changePassword')}}</div>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return {
      codeTime: 0,
      email: '',
      code: '',
      password: '',
      password2: ''
    }
  },
  methods: {
    getCode() {
      if (this.email === "") {
        this.$Msg(this.$t("login.emailCheck"));
        return;
      }
      if (this.codeTime <= 59 && this.codeTime > 0) {
        return;
      }
      let params = {
        game_id:this.$store.state.game_id,
        email:this.email
      }
      this.$http.post("/apis/h5/sendCode",  params ).then((res) => {
        if (res.code === 200) {
          this.codeTime = 59;
          let codeTimeInt = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (this.codeTime == 0) {
              clearInterval(codeTimeInt);
            }
          }, 1000);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
    confirm() {
      if (this.email && this.code && this.password && this.password2) {
        if (this.password === this.password2) {
          let params = {
            game_id: localStorage.getItem("game_id"), //游戏id
            email: this.email, //邮箱账号
            new_passwd: this.password, //密码
            auth_code: this.code, //短信验证码
          };
          this.$http.post("/apis/h5/modifyPasswd",  params ).then((res) => {
            if (res.code === 200) {
              this.$router.push("/main/my");
              this.$Msg(this.$t("login.PasswordSuccess"));
            } else {
              this.$Msg(res.msg);
            }
          });
        } else {
          this.$Msg(this.$t("login.differencePassword"));
        }
      }
    },
  }
}
</script>
<style scoped lang='scss'>
.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .8rem .3rem;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  font-size: .25rem;
}
.cell{
  display: flex;
  align-items: center;
  margin-bottom: .3rem;
  padding: 0 .3rem;
  width: 100%;
  height: .76rem;
  background-color: #eeeeee;
  border-radius: 1rem;
  box-sizing: border-box;
}
.cell_icon{
  margin-right: .2rem;
  width: .45rem;
  height: .45rem;
}
.cell_input{
  flex: 1;
  overflow: hidden;
}
.cell_input input{
  width: 100%;
  height: .3rem;
}
.cell_input input::placeholder{
  color: #8d8d8d;
  font-size: .25rem;
}
.login_code{
  display: flex;
  justify-content: flex-start;
  margin-bottom: .3rem;
  width: 100%;
}
.login_code .cell{
  flex: 1;
  margin: 0;
  width: auto;
}
.login_code_btn{
  margin-left: .2rem;
  width: 1.8rem;
  height: .76rem;
  line-height: .76rem;
  text-align: center;
  color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 1rem;
  box-sizing: border-box;
}
.submit_btn{
  margin-top: .2rem;
  width: 90%;
  line-height: .7rem;
  text-align: center;
  background-color: $color-primary;
  color: #fff;
  border-radius: 1rem;
}
</style>